import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layoutEN"
import BluePageContent from "../../components/StyledElements/bluePageContent"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import styled from "styled-components"
import MFsquaresWhiteBottom from "../../components/StyledElements/mfsquaresWhiteBottom"
import { ContactForm } from "../../components/contactform/contactformEN"
import Seo from "gatsby-plugin-wpgraphql-seo"

const kontakt = ({ data }) => {
  const { allWpEngelsk } = data

  return (
    <>
      <Seo post={allWpEngelsk.nodes[0]} />
      <Layout>
        <BluePageContent>
          <h1>{allWpEngelsk.nodes[0].title}</h1>
          <TwoGrid>
            <div>
              <Heading>
                {allWpEngelsk.nodes[0].ACFkontaktside.herokontakt.herosubhead}
              </Heading>
              <Text>
                {parse(
                  `${allWpEngelsk.nodes[0].ACFkontaktside.herokontakt.heroText}`
                )}
              </Text>
            </div>
            <div>
              <GatsbyImage
                image={
                  allWpEngelsk.nodes[0].ACFkontaktside.herokontakt.heroimg
                    .localFile.childImageSharp.gatsbyImageData
                }
                alt={
                  allWpEngelsk.nodes[0].ACFkontaktside.herokontakt.heroimg.altText
                }
              />
            </div>
          </TwoGrid>
          <ContactForm />
          <MFsquaresWhiteBottom />
        </BluePageContent>
      </Layout>
    </>
  )
}

export default kontakt

export const query = graphql`
  {
    allWpEngelsk(filter: { databaseId: { eq: 1308 } }) {
      nodes {
        databaseId
        title
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        ACFkontaktside {
          herokontakt {
            heroText
            herosubhead
            heroimg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    height: 480
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

const TwoGrid = styled.div`
  @media (min-width: 993px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 8rem;
    padding: 50px 0px;
    position: relative;
  }
`

const Text = styled.span`
  font-weight: 300;
  color: #eff1ef;
  & p {
    max-width: 600px;
    font-size: 18px;
    font-weight: 300;
    padding-left: 0 !important;
  }
`

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #eff1ef;
  max-width: 600px;
  margin-top: 0px !important;
`
